<template>
  <div>
<!--    <el-card style="width: 20pc;margin-left: 40pc" v-show="selectShow">
      <el-table :data="sectors" @selection-change="sectorChange" border >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column prop="sector" :label="$t('msg.rate.sector')" sortable align="center" ></el-table-column>
      </el-table>
      <el-button type="primary" @click="toFillForm()">Next</el-button>
    </el-card>-->
    <el-card style="width: 40pc;margin-left: 30pc" v-show="formVisible">
      <el-form :model="formData" :label-position="labelPosition" label-width="200px">
        <el-form-item :label="$t('msg.rate.payTerm')">
          <el-select v-model="formData.payTerm" >
            <el-option v-for="item in payTerms" :key="item" :label="item" :value="item"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.pol')">
          <el-select v-model="formData.pol" >
            <el-option v-for="item in pols" :key="item" :label="item" :value="item"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.pod')">
          <el-select v-model="formData.pod"  v-on:change="getToArea()">
            <el-option v-for="item in pods" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="width: 40pc;margin-left: 30pc" v-show="formVisible">
      <el-form :model="drayForm" :label-position="labelPosition" label-width="200px">
        <el-form-item :label="$t('msg.dray.desType')" prop="desType" required  >
          <el-select v-model="drayForm.desType" placeholder="Destination Type" v-on:change="getToArea()">
            <el-option label="FBA" value="FBA"></el-option>
            <el-option label="City" value="City"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.dray.toArea')" prop="toArea" required >
          <el-select v-model="drayForm.toArea" placeholder="Destination" >
            <el-option v-for="item in toAreaList" :key="item.name" :label="item.name" :value="item.name"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.dray.ctnrType')" prop="ctnrType" required >
          <el-select v-model="drayForm.ctnrType" placeholder="Container Size">
            <el-option label="20GP" value="20GP"></el-option>
            <el-option label="40GP" value="40GP"></el-option>
            <el-option label="40HQ" value="40HQ"></el-option>
            <el-option label="45HQ" value="45HQ"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.dray.dropType')" prop="dropType" required >
          <el-select v-model="drayForm.dropType" placeholder="Drop Type">
            <el-option label="Live" value="Live"></el-option>
            <el-option label="Drop" value="Drop"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.dray.mapType')" prop="mapType">
          <el-select v-model="drayForm.mapType" placeholder="Map Type" disabled>
            <el-option label="p10" value="p10"></el-option>
            <el-option label="p5" value="p5"></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-button type="primary" @click="getQuote()" style="float: right">{{$t('msg.rate.getQuote')}}</el-button>
      </el-form>
    </el-card>
    <el-card v-show="oceanQuoteShow">
      <el-card v-show="drayQuoteShow">
        <el-button-group>
          <el-button class="normButton" type="primary" @click="download()">{{$t('msg.rate.downloadCSV')}}</el-button>
          <el-button class="normButton" type="primary" @click="editShow()">{{$t('msg.rate.createQuotePDF')}}</el-button>
        </el-button-group>
        <el-divider></el-divider>
        <el-descriptions :title="$t('msg.account.quoteSummary')" column=4 v-show="summaryShow">
          <el-descriptions-item :label="$t('msg.account.lowComboRate')"><el-tag >$ {{lowRecord.lowComboRate}}</el-tag></el-descriptions-item>
          <el-descriptions-item :label="$t('msg.account.lowComboMargin')"><el-tag >$ {{lowRecord.lowComboMargin}}</el-tag></el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-table ref="tableRef" :data="tableData" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName" border>
        <el-table-column :label="$t('msg.rate.quotePayload')" align="center">
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column prop="pol" :label="$t('msg.rate.pol')" sortable align="center" ></el-table-column>
          <el-table-column prop="pod" :label="$t('msg.rate.pod')" sortable align="center" ></el-table-column>
          <el-table-column prop="ipi" :label="$t('msg.rate.ipi')" sortable align="center" ></el-table-column>
          <el-table-column prop="carrier" :label="$t('msg.rate.carrier')" sortable align="center" ></el-table-column>
          <el-table-column prop="remark" :label="$t('msg.rate.remark')" sortable align="center" ></el-table-column>
          <el-table-column prop="c20GP" label="20GP" sortable align="center" >
            <template #default="scope">
              <span >$ {{scope.row.c20GP}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="c40GP" label="40GP" sortable align="center" >
            <template #default="scope">
              <span >$ {{scope.row.c40GP}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="c40HQ" label="40HQ" sortable align="center" >
            <template #default="scope">
              <span >$ {{scope.row.c40HQ}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="c45HQ" label="45HQ" sortable align="center" >
            <template #default="scope">
              <span >$ {{scope.row.c45HQ}}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center">
          <template #header>
            <span class="mySpan">{{$t('msg.rate.projectProfit')}}</span>
          </template>
          <el-table-column prop="pc20GP" sortable align="center" >
            <template #header>
              <span class="mySpan">20GP</span>
            </template>
            <template #default="scope">
              <span class="mySpan">$ {{scope.row.pc20GP}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="pc40GP" sortable align="center" >
            <template #header>
              <span class="mySpan">40GP</span>
            </template>
            <template #default="scope">
              <span class="mySpan">$ {{scope.row.pc40GP}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="pc40HQ" sortable align="center" >
            <template #header>
              <span class="mySpan">40HQ</span>
            </template>
            <template #default="scope">
              <span class="mySpan">$ {{scope.row.pc40HQ}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="pc45HQ" sortable align="center" >
            <template #header>
              <span class="mySpan">45HQ</span>
            </template>
            <template #default="scope">
              <span class="mySpan">$ {{scope.row.pc45HQ}}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-divider></el-divider>
      <el-tag style="float: right;margin-bottom: 20px" size="large" round v-show="tagShow">L2 Tag {{l2Owner}} {{l2Tag}}</el-tag>
    </el-card>
    <el-card v-show="drayQuoteShow">
      <el-descriptions :title="$t('msg.dray.costEstimate')" column=4>
        <el-descriptions-item :label="$t('msg.rate.projectProfit')+ ':'"><el-tag >$ {{drayProfit}}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('msg.dray.fromArea')+ ':'"><el-tag >{{drayCost.fromArea}}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('msg.dray.toArea')+ ':'"><el-tag >{{drayCost.toArea}}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('msg.dray.lspName')+ ':'"><el-tag >{{drayCost.lspName}}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('msg.dray.ctnrType')+ ':'"><el-tag >{{drayCost.ctnrType}}</el-tag></el-descriptions-item>
      </el-descriptions>
      <el-row >
        <el-col :span="11" style="margin-right: 20px">
          <el-card>
            <el-descriptions :title="$t('msg.dray.costEstimateAP')">
              <el-descriptions-item :label="$t('msg.dray.drayage')+ ':'">$ {{drayCostAP.drayage}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.fuelSurcharge')+ ':'">$ {{drayCostAP.fuelSurcharge}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.chassisRent')+ ':'">$ {{drayCostAP.chassisRent}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.liveUnload')+ ':'">$ {{drayCostAP.liveUnload}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.bobTail')+ ':'">$ {{drayCostAP.bobTail}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.pierPass')+ ':'">$ {{drayCostAP.pierPass}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.terminalFee')+ ':'">$ {{drayCostAP.terminalFee}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.portCongest')+ ':'">$ {{drayCostAP.portCongest}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.cleanTruck')+ ':'">$ {{drayCostAP.cleanTruck}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.normTotal')+ ':'">$ {{drayCostAP.normTotal}}</el-descriptions-item>
            </el-descriptions>
          </el-card>
        </el-col>
        <el-col :span="11">
          <el-card>
            <el-descriptions :title="$t('msg.dray.costEstimateAR')">
              <el-descriptions-item :label="$t('msg.dray.drayage')">$ {{drayCost.drayage}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.fuelSurcharge')">$ {{drayCost.fuelSurcharge}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.chassisRent')">$ {{drayCost.chassisRent}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.liveUnload')">$ {{drayCost.liveUnload}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.bobTail')">$ {{drayCost.bobTail}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.pierPass')">$ {{drayCost.pierPass}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.terminalFee')">$ {{drayCost.terminalFee}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.portCongest')">$ {{drayCost.portCongest}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.cleanTruck')">$ {{drayCost.cleanTruck}}</el-descriptions-item>
              <el-descriptions-item :label="$t('msg.dray.normTotal')">$ {{drayCost.normTotal}}</el-descriptions-item>
            </el-descriptions>
          </el-card>
        </el-col>
      </el-row>
      <el-collapse v-model="collapse" accordion>
        <el-collapse-item name="1">
          <template #title >
            <span style="color: #0ea5e9">{{$t('msg.dray.reviewFullCard')}}</span>
          </template>
          <el-table :data="drayRate" border>
            <el-table-column prop="item" :label="$t('msg.rate.item')" align="center"></el-table-column>
            <el-table-column prop="c1" :label="$t('msg.rate.c1')" align="center"></el-table-column>
            <el-table-column prop="c2" :label="$t('msg.rate.c2')" align="center"></el-table-column>
            <el-table-column prop="ratePerUOM" :label="$t('msg.rate.ratePerUOM')" align="center">
              <template #default="scope">
                <span v-if="scope.row.item==='Criteria'">{{scope.row.ratePerUOM}}</span>
                <span v-else>$ {{scope.row.ratePerUOM}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="adjust" :label="$t('msg.rate.adjust')" align="center">
              <template #default="scope">
                <span v-if="scope.row.item==='Criteria'">{{scope.row.adjust}}</span>
                <span v-else>$ {{scope.row.adjust}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="uom" :label="$t('msg.rate.uom')" align="center"></el-table-column>
            <el-table-column prop="notes" :label="$t('msg.rate.notes')" align="center"></el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-dialog title="Quote Basic Information" v-model="editFormVisible" v-on:close="resetForm()" width="30%" center draggable>
      <el-radio-group v-model="clientType" >
        <el-radio-button label="New Client">{{$t('msg.rate.newClient')}}</el-radio-button>
        <el-radio-button label="Old Client">{{$t('msg.rate.oldClient')}}</el-radio-button>
      </el-radio-group>
      <el-divider></el-divider>
      <el-form :model="editFormData" >
        <el-form-item :label="$t('msg.rate.clientName')" prop="clientName" style="width: 350px">
          <el-input v-model="editFormData.clientName" v-if="clientType==='New Client'"></el-input>
          <el-select v-model="editFormData.oldClient" placeholder="Select" style="width: 120px" v-else>
            <el-option v-for="item in clientList" :key="item.name" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.l3Owner')" prop="l3Owner" required style="width: 420px">
          <el-select v-model="editFormData.l3Owner" placeholder="L3 Owner">
            <el-option label="Surpath" value="Surpath"></el-option>
            <el-option label="E2E" value="E2E"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.pageRemark')" prop="pageRemark" required style="width: 350px">
          <el-input v-model="editFormData.pageRemark"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.startDate')" prop="startDate" required >
          <el-date-picker v-model="editFormData.startDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.endDate')" prop="endDate" required >
          <el-date-picker v-model="editFormData.endDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
        </el-form-item>
      </el-form>
      <el-button type="primary" v-on:click="pdfDownload()" style="float: right">Submit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onActivated, ref } from 'vue'
import axios from 'axios'
import { getOceanQuote, getPolPodList } from '@/api/rate'
import { useI18n } from 'vue-i18n'
import { csvDownload, pdfGenerate } from '@/utils/download'
export default defineComponent({
  name: 'comboQuote',
  setup() {
    const selectShow = ref(true)
    const sectors = ref([{ sector: 'Ocean Freight' }, { sector: 'Custom House Brokerage' }, { sector: 'Dray' }])
    const selectedSectors = ref([])
    const formVisible = ref(true)
    const drayFormShow = ref(true)
    const labelPosition = ref('left')
    const toAreaList = ref([])
    const formData = ref({ pol: '', pod: '', payTerm: '', srvPort: 'LGB', chbSrv: 'Yes', draySrv: 'No' })
    const drayForm = ref({ desType: 'City', toArea: '', ctnrType: '', dropType: '', mapType: 'p10' })
    const drayBillSum = ref()
    const pols = ref([])
    const pods = ref([])
    const payTerms = ref([])
    const summaryShow = ref(false)
    const drayQuoteShow = ref(false)
    const oceanQuoteShow = ref(false)
    const tableData = ref([])
    const lowRateIndex = ref([])
    const lowRecord = ref({ lowComboRate: 0, lowComboMargin: 0 })
    const l2Tag = ref('')
    const l2Owner = ref('')
    const tagShow = ref(false)
    const drayProfit = ref(0)
    const drayCost = ref({ fromArea: '', toArea: '', lspName: '', ctnrType: '', drayage: 0, fuelSurcharge: 0, chassisRent: 0, liveUnload: 0, bobTail: 0, pierPass: 0, terminalFee: 0, portCongest: 0, cleanTruck: 0, normTotal: 0 })
    const drayCostAP = ref({ fromArea: '', toArea: '', lspName: '', ctnrType: '', drayage: 0, fuelSurcharge: 0, chassisRent: 0, liveUnload: 0, bobTail: 0, pierPass: 0, terminalFee: 0, portCongest: 0, cleanTruck: 0, normTotal: 0 })
    const drayRate = ref([])
    const editFormData = ref({ clientName: '', oldClient: '', l3Owner: '', startDate: '', endDate: '', pageRemark: '' })
    const editFormVisible = ref(false)
    const clientType = ref('New Client')
    const clientList = ref([])
    const collapse = ref('')
    const multipleSelection = ref([])
    const curUser = localStorage.getItem('curUser')
    const i18n = useI18n()
    const sectorChange = (val) => {
      selectedSectors.value = val
    }
    const toFillForm = () => {
      for (let i = 0; i < selectedSectors.value.length; i++) {
        if (selectedSectors.value[i].sector === 'Ocean Freight') {
          formVisible.value = true
        } else if (selectedSectors.value[i].sector === 'Dray') {
          drayQuoteShow.value = true
        }
      }
    }
    const getToArea = () => {
      drayForm.value.toArea = ''
      const param = 'port=' + formData.value.pod + '&toType=' + drayForm.value.desType
      const reqURL = process.env.VUE_APP_BASE + '/dray/getDestList'
      axios.post(reqURL, param).then((response) => {
        toAreaList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const getOceanOnly = () => {
      getOceanQuote({
        term: formData.value.payTerm,
        fromArea: formData.value.pol,
        toArea: formData.value.pod
      }).then(res => {
        tableData.value = res.ffcsopQuotes
        l2Tag.value = res.l2Tag
        l2Owner.value = res.l2Owner
        oceanQuoteShow.value = true
        tagShow.value = true
      })
    }
    const getComboQuote = () => {
      const param = 'pol=' + formData.value.pol + '&pod=' + formData.value.pod + '&payTerm=' + formData.value.payTerm + '&desType=' + drayForm.value.desType +
        '&toArea=' + drayForm.value.toArea + '&ctnrType=' + drayForm.value.ctnrType + '&dropType=' + drayForm.value.dropType + '&mapType=' + drayForm.value.mapType
      const reqURL = process.env.VUE_APP_BASE + '/ocean/OceanDrayComboQuote'
      axios.post(reqURL, param).then((response) => {
        tableData.value = response.data.oceanQuote.ffcsopQuotes
        l2Tag.value = response.data.oceanQuote.l2Tag
        l2Owner.value = response.data.oceanQuote.l2Owner
        drayCost.value = response.data.drayQuote.drayCostDetail
        drayCostAP.value = response.data.drayQuote.drayCostAP
        drayRate.value = response.data.drayQuote.drayRateList
        drayProfit.value = Math.round(drayCost.value.normTotal - drayCostAP.value.normTotal)
        getLowComboRecord()
        oceanQuoteShow.value = true
        tagShow.value = true
        drayQuoteShow.value = true
      }).catch((response) => {
        console.log(response)
      })
    }
    const getQuote = () => {
      if (drayForm.value.toArea === 'No Service' || drayForm.value.toArea === '') {
        getOceanOnly()
      } else {
        getComboQuote()
      }
      summaryShow.value = formData.value.pol !== 'All'
      formVisible.value = false
    }
    const handleSelectionChange = (val) => {
      multipleSelection.value = val
    }
    const editShow = () => {
      if (multipleSelection.value.length === 0) {
        alert('Please Select at least 1 Base Rate as Quote')
        return false
      }
      const param = 'curName=' + localStorage.getItem('curUser')
      const reqURL = process.env.VUE_APP_BASE + '/ocean/getUniqueClientName'
      axios.post(reqURL, param).then((response) => {
        clientList.value = response.data
        editFormVisible.value = true
      }).catch((response) => {
        console.log(response)
      })
    }
    const resetForm = () => {
      editFormData.value = { clientName: '', oldClient: 'New Client', l3Owner: '', startDate: '', endDate: '', pageRemark: '' }
    }
    const pdfDownload = () => {
      const client = clientType.value === 'New Client' ? editFormData.value.clientName : editFormData.value.oldClient
      const param = 'baseRates=' + JSON.stringify(multipleSelection.value) + '&drayRates=' + JSON.stringify(drayRate.value) + '&clientName=' +
        client + '&l3Owner=' + editFormData.value.l3Owner + '&startDate=' + editFormData.value.startDate + '&endDate=' +
        editFormData.value.endDate + '&pageRemark=' + editFormData.value.pageRemark + '&curUser=' + curUser + '&lspName=' + drayCost.value.lspName +
        '&srvPort=' + drayCost.value.fromArea + '&desType=' + drayForm.value.desType + '&drayCost=' + JSON.stringify(drayCost.value) + '&quoteType=Combo-OF-Dray'
      const myUrl = '/ocean/ComboQuotePDF'
      const downloadName = editFormData.value.l3Owner + '-Quote.pdf'
      editFormVisible.value = false
      pdfGenerate(myUrl, param, downloadName)
    }
    const download = () => {
      const downloadName = 'OceanFreight-Quote.csv'
      const param = 'baseRates=' + JSON.stringify(tableData.value)
      const url = '/ocean/l2CSVDl'
      csvDownload(url, param, downloadName)
    }
    const getListData = async () => {
      const result = await getPolPodList()
      pols.value = result.polList
      pods.value = result.podList.slice(1)
      payTerms.value = result.payTermList
    }
    onActivated(getListData)
    const getLowComboRecord = () => {
      const rates = tableData.value
      lowRateIndex.value.push(0)
      if (rates.length <= 1) return
      for (let i = 1; i < rates.length; i++) {
        if (rates[i].pol !== rates[i - 1].pol || rates[i].pod !== rates[i - 1].pod) {
          lowRateIndex.value.push(i)
        }
      }
      if (formData.value.pol !== 'All') {
        const ctnr = drayForm.value.ctnrType
        const record = rates[0]
        if (ctnr === '20GP') {
          lowRecord.value = { lowComboRate: record.c20GP, lowComboMargin: record.pc20GP }
        } else if (ctnr === '40GP') {
          lowRecord.value = { lowComboRate: record.c40GP, lowComboMargin: record.pc40GP }
        } else if (ctnr === '40HQ') {
          lowRecord.value = { lowComboRate: record.c40HQ, lowComboMargin: record.pc40HQ }
        } else if (ctnr === '45HQ') {
          lowRecord.value = { lowComboRate: record.c45HQ, lowComboMargin: record.pc45HQ }
        }
        lowRecord.value.lowComboRate = Math.round(lowRecord.value.lowComboRate + drayCost.value.normTotal)
        lowRecord.value.lowComboMargin = Math.round(lowRecord.value.lowComboMargin + drayProfit.value)
      }
    }
    const tableRowClassName = (val) => {
      if (lowRateIndex.value.includes(val.rowIndex)) {
        return 'lowRate-row'
      } else {
        return ''
      }
    }
    return { tableRowClassName, toFillForm, sectors, selectShow, selectedSectors, lowRateIndex, lowRecord, drayFormShow, summaryShow, getLowComboRecord, resetForm, pdfDownload, sectorChange, getComboQuote, getOceanOnly, getQuote, drayProfit, drayRate, collapse, toAreaList, drayCost, drayCostAP, editFormData, getToArea, labelPosition, formVisible, download, drayForm, oceanQuoteShow, drayQuoteShow, multipleSelection, drayBillSum, formData, pols, pods, payTerms, editShow, curUser, i18n, l2Tag, l2Owner, tagShow, clientType, clientList, editFormVisible, tableData, handleSelectionChange }
  }
})

</script>
<style>
.mySpan{
  color: #0ea5e9;
}
.el-table .lowRate-row {
  background: #e0f6e0 !important;
}
</style>
