import request from '@/utils/request'

export const getPolPodList = () => {
  return request({
    url: '/ocean/getPolPodList'
  })
}
export const getClientList = () => {
  return request({
    url: '/ocean/getUniqueClientName'
  })
}
export const tryDl = data => {
  return request({
    url: '/ocean/l2CSVDl',
    method: 'POST',
    data
  })
}
export const getOceanQuote = data => {
  return request({
    url: '/ocean/quote',
    params: data
  })
}
export const getOceanRateList = data => {
  return request({
    url: '/ocean/oceanFreight',
    params: data
  })
}

export const rateSort = data => {
  return request({
    url: '/rate/sort',
    method: 'POST',
    data
  })
}

export const rateDetail = rateId => {
  return request({
    url: `/rate/${rateId}`
  })
}
